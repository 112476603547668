import React from "react";
import styled from "styled-components";
import ty from "../styled-tachyons";
import { Button, DarkGraySection, SubHeader } from "../styles";

export const SupportCTA = styled(({ className, noun = "Projects" }) => {
  return (
    <DarkGraySection className={className}>
      <SubHeader>Want to Support More {noun} Like These?</SubHeader>
      <Button ty="bg-near-black white b--washed-red center" to="/get-involved">
        Get Involved
      </Button>
    </DarkGraySection>
  );
})`
  ${ty`tc`}
  ${SubHeader} {
    font-family: nunito, sans-serif;
    ${ty`f2 mb3 fw5`}
  }
`;
