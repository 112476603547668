import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { FlexRow, ArrowButton } from "../styles";
import ty from "../styled-tachyons";

const ProjectNavigationStyle = styled(FlexRow)`
  ${ty`justify-between mb4`}
`;

const nodeOrIndexLink = (node, { textAttr, indexLink, indexText }) => {
  let text = _.get(node, textAttr);
  let to = _.get(node, "fields.slug");
  if (!to) {
    to = indexLink;
    indexText = indexText || "";
    text = `ALL ${indexText.toUpperCase()}`;
  }

  return {
    to,
    text,
  };
};

export const DetailNavigation = ({
  className,
  indexText,
  indexLink,
  nextNode,
  previousNode,
  textAttr = "fields.artistNode.frontmatter.name",
}) => {
  let previous, next;
  let { to: previousTo, text: previousText } = nodeOrIndexLink(previousNode, {
    textAttr,
    indexText,
    indexLink,
  });

  previous = (
    <ArrowButton
      arrowDirection="left"
      to={previousTo}
      arrowColor="red"
      ty="bg-near-black white ttn ph3"
    >
      {previousText}
    </ArrowButton>
  );

  let { to: nextTo, text: nextText } = nodeOrIndexLink(nextNode, {
    textAttr,
    indexText,
    indexLink,
  });
  next = (
    <ArrowButton
      arrowDirection="right"
      to={nextTo}
      arrowColor="white"
      ty="bg-near-black white ttn ph3 b--washed-red"
    >
      {nextText}
    </ArrowButton>
  );

  if (nextTo === previousTo) {
    next = null;
  }

  return (
    <ProjectNavigationStyle className={className}>
      {previous}
      {next}
    </ProjectNavigationStyle>
  );
};
