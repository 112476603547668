import _ from "lodash";
import React from "react";
import styled from "styled-components";
import { InfoCard } from "./InfoCard";
import {
  PageHeader,
  BodyText,
  StyledLink,
  RichText,
  Image,
  lQuery,
  DarkGraySection,
} from "../styles";
import ty from "../styled-tachyons";

export const ProjectInfoSection = styled(DarkGraySection)`
  ${Image}, ${InfoCard} {
    ${ty`mb3`}
  }
  ${Image} {
    ${ty`w-75-l ma0-l`}
  }
  ${InfoCard} {
    ${ty`w-100 absolute-l ba-l b--gray`}
    bottom: 5%;
    right: 0;

    ${lQuery} {
      width: auto;
      ${({ hasDescription }) =>
        hasDescription
          ? {
              transform: "translateY(-50%)",
            }
          : null}
    }
  }

  ${RichText} {
    ${ty`bg-white b--gray near-black ph4 pv3`}
    ${ty`w-80-l ba-ns b--gray`}
    ${lQuery} {
      margin-top: -55px;
      position: relative;
      transform: translateX(20%);
      z-index: 5;
    }
  }
`;

export const Rowish = styled.div`
  ${ty`relative`}
`;
export const DetailHero = ({
  navigation,
  pageTitle,
  link,
  coverImage,
  cardTitle,
  title,
  subTitle,
  subSubTitle,
  description_html,
}) => {
  let linkText;
  if (link) {
    linkText = (
      <BodyText>
        <StyledLink to={link}>{link}</StyledLink>
      </BodyText>
    );
  } else {
    // for spacing
    linkText = <BodyText></BodyText>;
  }
  let coverImageDisplay;
  if (coverImage) {
    let imageProps = _.isString(coverImage)
      ? { src: coverImage }
      : { fluid: coverImage?.childImageSharp?.fluid };
    coverImageDisplay = <Image {...imageProps} />;
  }

  let description;
  if (description_html) {
    description = (
      <RichText
        dangerouslySetInnerHTML={{ __html: description_html }}
      ></RichText>
    );
  }

  return (
    <ProjectInfoSection hasDescription={!!description}>
      {navigation}
      <PageHeader>{pageTitle}</PageHeader>
      {linkText}
      <Rowish>
        {coverImageDisplay}
        <InfoCard
          cardTitle={cardTitle}
          title={title}
          subTitle={subTitle}
          subSubTitle={subSubTitle}
        />
      </Rowish>
      {description}
    </ProjectInfoSection>
  );
};
