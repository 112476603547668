import React from "react";
import styled from "styled-components";
import ty from "../styled-tachyons";
import { DarkGraySection, RichText, SectionHeader } from "../styles";

const DetailTextSectionUnstyled = ({ className, title, html }) => {
  if (!html) {
    return null;
  }
  return (
    <DarkGraySection className={className}>
      <SectionHeader>{title}</SectionHeader>
      <RichText dangerouslySetInnerHTML={{ __html: html }}></RichText>
    </DarkGraySection>
  );
};
export const DetailTextSection = styled(DetailTextSectionUnstyled)`
  ${ty`tc`}

  ${RichText} {
    ${ty`mw8 center`}
  }
`;
