import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { DetailHero } from "../components/DetailHero";
import { DetailImages } from "../components/DetailImages";
import { DetailNavigation } from "../components/DetailNavigation";
import { DetailTextSection } from "../components/DetailTextSection";
import Layout from "../components/Layout";
import MetaTags from "../components/MetaTags";
import { SupportCTA } from "../components/SupportCTA";
import ty from "../styled-tachyons";
import { BodyText, Image, PageHeader } from "../styles";

const ProjectDetailPageStyle = styled.div`
  ${Image} {
    max-width: 100%;
  }
  ${PageHeader} {
    ${ty`mb1`}

    & + ${BodyText} {
      ${ty`mt1 mb5`}
    }
  }
`;

export const ProjectDetailPageTemplate = ({
  title: pageTitle,
  project,
  artist = {},
  previousNode,
  nextNode,
}) => {
  let {
    ivr,
    link: projectLink,
    coverImage,
    title,
    subTitle,
    subSubTitle,
    description_excerpt,
    description_html,
  } = project;
  let { name: artistName, link: artistLink } = artist;

  let link = projectLink || artistLink;
  const navigation = (
    <DetailNavigation
      indexLink="/artists"
      indexText="Artists"
      textAttr="fields.artistNode.frontmatter.name"
      previousNode={previousNode}
      nextNode={nextNode}
    />
  );

  return (
    <ProjectDetailPageStyle>
      <MetaTags title={pageTitle} description={description_excerpt} />
      <DetailHero
        navigation={navigation}
        pageTitle={artistName}
        link={link}
        coverImage={coverImage}
        cardTitle={ivr}
        title={title}
        subTitle={subTitle}
        subSubTitle={subSubTitle}
        description_html={description_html}
      />
      <DetailImages project={project} />
      <DetailTextSection title="About the Artist" html={artist.bio_html} />
      <SupportCTA noun="Projects" />
    </ProjectDetailPageStyle>
  );
};

ProjectDetailPageTemplate.propTypes = {};

const ProjectDetailPage = (p) => {
  const { data } = p;
  const { frontmatter } = data.markdownRemark;
  //console.log(data);

  return (
    <Layout>
      <ProjectDetailPageTemplate
        title={frontmatter.title}
        project={frontmatter}
        artist={data.markdownRemark?.fields?.artistNode?.frontmatter}
        previousNode={data.markdownRemark?.fields?.previousNode}
        nextNode={data.markdownRemark?.fields?.nextNode}
      />
    </Layout>
  );
};

ProjectDetailPageStyle.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProjectDetailPage;

export const pageQuery = graphql`
  query ProjectDetailPageTemplate($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      fields {
        artistNode {
          id
          frontmatter {
            name
            link
            bio_html
          }
        }

        previousNode {
          id
          fields {
            slug
            artistNode {
              frontmatter {
                name
              }
            }
          }
        }
        nextNode {
          id
          fields {
            slug
            artistNode {
              frontmatter {
                name
              }
            }
          }
        }
      }
      frontmatter {
        ivr
        title
        subTitle
        subSubTitle
        link
        artist
        description_excerpt
        description_html

        coverImage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        detailImage {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        topDetailImage {
          ...smallDetailImage
        }
        bottomDetailImage {
          ...smallDetailImage
        }
      }
    }
  }
`;
