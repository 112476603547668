import { graphql } from "gatsby";
import _ from "lodash";
import React from "react";
import styled from "styled-components";
import ty from "../styled-tachyons";
import {
  ColThird,
  ColTwoThirds,
  DarkGraySection,
  FlexRow,
  Image,
} from "../styles";

const DetailImagesUnstyled = ({ className, project }) => {
  let { detailImage, topDetailImage, bottomDetailImage } = project;

  let sideImages;
  if (topDetailImage || bottomDetailImage) {
    let topImage;
    if (topDetailImage) {
      let imageProps = _.isString(topDetailImage)
        ? { src: topDetailImage }
        : {
            fluid: {
              ...topDetailImage?.childImageSharp?.fluid,
              aspectRatio: 1.57,
            },
          };
      topImage = <Image {...imageProps} />;
    }
    let bottomImage;
    if (bottomDetailImage) {
      let imageProps = _.isString(bottomDetailImage)
        ? { src: bottomDetailImage }
        : {
            fluid: {
              ...bottomDetailImage?.childImageSharp?.fluid,
              aspectRatio: 1.57,
            },
          };
      bottomImage = <Image {...imageProps} />;
    }

    sideImages = (
      <ColThird>
        {topImage}
        {bottomImage}
      </ColThird>
    );
  }
  let mainImage;
  if (detailImage) {
    let imageProps = _.isString(detailImage)
      ? { src: detailImage }
      : {
          fluid: {
            ...detailImage?.childImageSharp?.fluid,
            // aspectRatio: 1.6,
          },
        };
    mainImage = (
      <ColTwoThirds>
        <Image {...imageProps} />
      </ColTwoThirds>
    );
  }

  if (_.isEmpty(sideImages) && _.isEmpty(mainImage)) {
    return <></>;
  }

  return (
    <DarkGraySection className={className}>
      <FlexRow>
        {sideImages}
        {mainImage}
      </FlexRow>
    </DarkGraySection>
  );
};
export const DetailImages = styled(DetailImagesUnstyled)`
  ${ColThird}, ${ColTwoThirds} {
    ${ty`pa0-l`}
  }
  ${ColThird} {
    ${Image} {
      ${ty`mr4-l`}
    }
    ${Image}:first-child {
      ${ty`mb3 mb4-l`}
    }
  }
`;

export const query = graphql`
  fragment smallDetailImage on File {
    childImageSharp {
      fluid(maxWidth: 1600, sizes: "(min-width: 60em) calc(.333 * 100vw)") {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;
