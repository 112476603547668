import React from "react";
import styled from "styled-components";
import ty from "../styled-tachyons";
import {
  ArrowButton,
  BodyText,
  lQuery,
  rem,
  SectionHeader,
  SubHeader,
} from "../styles";

const Content = styled.div``;
export const CarouselPageTitle = styled(SubHeader)`
  ${ty`i`}
`;
export const CarouselPageSubTitle = styled(BodyText)`
  ${ty`fw6 mb0`}
`;
export const CarouselPageSubSubTitle = styled(BodyText)`
  ${ty`mt0`}
`;
const InfoCardUnstyled = ({
  className,
  cardTitle,
  title,
  subTitle,
  subSubTitle,
  actionUrl,
}) => {
  let titles = [];
  if (title) {
    titles.push(<CarouselPageTitle key="title">{title}</CarouselPageTitle>);
  }
  if (subTitle) {
    titles.push(
      <CarouselPageSubTitle key="subTitle">{subTitle}</CarouselPageSubTitle>
    );
  }
  if (subSubTitle) {
    titles.push(
      <CarouselPageSubSubTitle key="subsubtitle">
        {subSubTitle}
      </CarouselPageSubSubTitle>
    );
  }

  let action;
  if (actionUrl) {
    action = (
      <ArrowButton ty="bg-near-black white" arrowColor="red" to={actionUrl}>
        Learn more
      </ArrowButton>
    );
  }

  return (
    <div className={className}>
      <SectionHeader>{cardTitle}</SectionHeader>
      <Content>
        {titles}
        {action}
      </Content>
    </div>
  );
};
export const InfoCard = styled(InfoCardUnstyled)`
  ${ty`bg-white`};
  ${lQuery} {
    max-width: ${rem(379)};
  }

  ${Content} {
    ${ty`ph4 pt3 pb4`};
  }

  ${SectionHeader} {
    ${ty`ph4 pv2 bg-washed-red f3 tl truncate`};
  }

  ${SubHeader},${BodyText} {
    ${ty`near-black tl`}
  }
  ${SubHeader} {
    ${ty`ttu`}
  }
  ${BodyText} {
    ${ty`mt0`}
  }
`;
